import React from "react";
import { Link as Lunk } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core/';

function OfficialDocs({anchorEl2, handleClose2}) {
    return (
        <Menu 
            id="simple-menu"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose2}>
                <MenuItem onClick={handleClose2} component={Lunk} to="/rules">HvZ Rules</MenuItem>
                <MenuItem onClick={handleClose2} component={Lunk} to="/rulesPresentation">HvZ Rules Presentation</MenuItem>
                <MenuItem onClick={handleClose2} component={Lunk} to="/bylaws">HvZ Bylaws</MenuItem>
                <MenuItem onClick={handleClose2} component={Lunk} to="/constitution">BGC Constitution</MenuItem>
                <MenuItem onClick={handleClose2}>
                    <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://docs.google.com/spreadsheets/d/10gH9vVnkVrY0KNtVYeRJXuE_PEPQlUAJmX127kiV3DA/edit?usp=sharing">Approved Blaster List</a>
                </MenuItem>
                <MenuItem onClick={handleClose2}>
                    <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://www.google.com/maps/d/u/0/edit?mid=1vXfT5I8b8tdPLGj9fHBBMGRmTbxaVAQ">Boundaries Map</a>
                </MenuItem>
                <MenuItem onClick={handleClose2}>
                    <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'rgb(33,33,33)' }} href="https://docs.google.com/document/d/e/2PACX-1vTl8bmfQ5KXIERh-O_WQTzhl-VyEDjPWK2za9GJn5_n6brZeq1NTucjiAKsDDejbbRUuCEL6zHua8Jj/pub">OZ Ritual Mechanic (Spring 2025 Only!)</a>
                </MenuItem>
        </Menu>
    );
}

export default OfficialDocs;
