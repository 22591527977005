import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title: {
        paddingTop: '20px',
        textAlign: 'center',
        color: 'white',
    }
}));

function Map(props) {
    const classes = useStyles();
    return (
        <Typography variant='h2' className={classes.title}>Infection Map</Typography>

    );
}

export default Map