import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
    notification: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto',
        background: 'white',
        marginBottom: '20px',
    },
    heading: {
        paddingTop: '5px',
    },
    content: {
        lineHeight: 'normal',
    },
    date: {
        color: 'grey',
    }
}));

function plural_if_needed(number, plural='s') {
    return (number > 1 || number === 0) ? plural : '';
}

function MissionResultCard({ summary }) {
    const classes = useStyles();
    return (
        <Card className={classes.notification}>
            <Typography variant='body1' className={classes.heading}><strong>{summary.name}</strong></Typography>
            <Typography variant='body1' className={classes.content}>{summary.location}</Typography>
            <Typography variant='body1' className={classes.date}>{format(summary.startDate, 'MMM do, yyyy h:mm')+' - ' + format(summary.endDate, 'h:mm a')}</Typography>
            {summary.resolved && <>
                {!!summary.attendeeCount && <>
                    <Typography variant='body1' className={classes.content}>{summary.attendeeCount} attendee{plural_if_needed(summary.attendeeCount)}</Typography>
                </>}
                {!summary.attendeeCount && !!summary.zombieCount && <>
                    <Typography variant='body1' className={classes.content}>{summary.humanCount} human{plural_if_needed(summary.humanCount)} vs {summary.zombieCount} zombie{plural_if_needed(summary.zombieCount)}</Typography>
                    <Typography variant='body1' className={classes.content}><strong>{summary.outcome}</strong></Typography>
                </>}
                {!summary.attendeeCount && !summary.zombieCount && !!summary.questMessage && <>
                    <Typography variant='body1' className={classes.content}>{summary.humanCount} human{plural_if_needed(summary.humanCount)} {summary.questMessage}</Typography>
                </>}
                {!summary.attendeeCount && !summary.zombieCount && !summary.questMessage && <>
                    {!!summary.outcome ? // Occurs for midnight where nobody checks in but there's still an outcome
                        <Typography variant='body1' className={classes.content}><strong>{summary.outcome}</strong></Typography>
                    :
                        <Typography variant='body1' className={classes.content}>{summary.humanCount} human{plural_if_needed(summary.humanCount)} attended</Typography>
                    }
                </>}
            </>}
        </Card>
    );

}

export default MissionResultCard;